import { InferType } from 'yup'
import { CreateAvailabilityFormSchema, CreateAvailabilityRequestBody } from 'schemas/Schedule'
import User from 'singletons/User'
import { endOfDay, parse, startOfDay } from 'date-fns'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = CreateAvailabilityFormSchema

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = { message: string }

export const url = () => `/v3/providers/${User.providerId}/schedules`

export const getBody = (values: Request) => {
  const {
    timeSegments: timeSegmentsValue,
    startDate: startDateValue,
    endDate: endDateValue,
    frequency,
    interval,
    byweekday,
  } = values || {}

  const startDate = startOfDay(parse(startDateValue, 'MM-dd-yyyy', new Date()))
  const endDate = endOfDay(parse(endDateValue, 'MM-dd-yyyy', new Date()))
  const timeSegments = timeSegmentsValue.reduce<[string, string][]>((acc, curr) => {
    return [
      ...acc,
      [
        parse(curr.startTime, 'hh:mmaaa', new Date(startDate)).toISOString(),
        parse(curr.endTime, 'hh:mmaaa', new Date(startDate)).toISOString(),
      ],
    ]
  }, [])

  const body: CreateAvailabilityRequestBody = {
    frequency: frequency || 'daily',
    interval: interval || 1,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    timeSegments,
    byweekday,
  }

  return body
}
