import { eachDayOfInterval, endOfWeek, format, isBefore, parse, startOfWeek } from 'date-fns'
import { array, boolean, InferType, number, object, string } from 'yup'

export const AVAILABILITY_FREQUENCIES_MAP = {
  weekly: 'weekly',
  daily: 'daily',
} as const

export const AVAILABILITY_FREQUENCY_OPTIONS = Object.keys(AVAILABILITY_FREQUENCIES_MAP)

export const getAvailabilityFrequencyLabel = (value?: string) =>
  ({
    [AVAILABILITY_FREQUENCIES_MAP.weekly]: 'Weekly',
    [AVAILABILITY_FREQUENCIES_MAP.daily]: 'Does not repeat',
  })[value] || value

export const WEEKLY_INTERVAL_OPTIONS = [1, 2]

export const getWeeklyIntervalLabel = (value?: number) =>
  ({
    1: 'Week',
    2: 'Every other week',
  })[value] || value

export const DateRangeAndRepeatFormSchema = object({
  startDate: string().label('Start Date').required().nullable().default(null),
  endDate: string()
    .label('End Date')
    .required()
    .nullable()
    .default(null)
    .test('minEndDate', 'End Date must be later than Start Date', function (endDate) {
      const { startDate } = this.parent || {}

      if (!endDate || !startDate) return true

      const startDateTimestamp = parse(startDate, 'MM-dd-yyyy', new Date()).valueOf()
      const endDateTimestamp = parse(endDate, 'MM-dd-yyyy', new Date()).valueOf()
      return endDateTimestamp >= startDateTimestamp
    }),
  frequency: string()
    .label('Repeat')
    .oneOf(AVAILABILITY_FREQUENCY_OPTIONS)
    .required()
    .nullable()
    .test('dailyEndDate', 'Start date and end date must be the same to select does not repeat', function (frequency) {
      const { startDate, endDate } = this.parent || {}

      if (!endDate || !startDate || frequency !== 'daily') return true

      return endDate === startDate
    }),
  interval: number()
    .label('Repeats Every')
    .nullable()
    .when('frequency', {
      is: (frequency) => frequency === AVAILABILITY_FREQUENCIES_MAP.weekly,
      then: (schema) => schema.oneOf(WEEKLY_INTERVAL_OPTIONS).required(),
      otherwise: (schema) => schema.notRequired(),
    }),
})

export type DateRangeAndRepeatFormValues = InferType<typeof DateRangeAndRepeatFormSchema>

const timeSegmentSchema = object({
  startTime: string().label('Start Time').required(),
  endTime: string()
    .label('End Time')
    .required()
    .test('minEndTime', 'End Time must be later than Start Time', function (endTime) {
      const { startTime } = this.parent || {}

      if (!endTime || !startTime) return true

      const startTimeTimestamp = parse(startTime, 'hh:mmaaa', new Date()).valueOf()
      const endTimeTimestamp = parse(endTime, 'hh:mmaaa', new Date()).valueOf()
      return endTimeTimestamp > startTimeTimestamp
    }),
})

export const WEEK_DAYS_OPTIONS = eachDayOfInterval({
  start: startOfWeek(new Date()),
  end: endOfWeek(new Date()),
}).map((d) => format(d, 'eeeeee').toUpperCase())

const hasOverlappingTimes = (times?: InferType<typeof timeSegmentSchema>[]) => {
  const parsedRanges = (times || [])
    .filter(({ startTime, endTime }) => !!startTime && endTime)
    .map(({ startTime, endTime }) => ({
      start: parse(startTime, 'hh:mmaaa', new Date()),
      end: parse(endTime, 'hh:mmaaa', new Date()),
    }))
    .sort((a, b) => a.start.getTime() - b.start.getTime())

  for (let i = 0; i < parsedRanges.length - 1; i++) {
    if (isBefore(parsedRanges[i + 1].start, parsedRanges[i].end)) {
      return true
    }
  }

  return false
}

export const CreateAvailabilityFormSchema = DateRangeAndRepeatFormSchema.concat(
  object({
    byweekday: array()
      .of(string().oneOf(WEEK_DAYS_OPTIONS))
      .when('frequency', {
        is: AVAILABILITY_FREQUENCIES_MAP.weekly,
        then: (schema) => schema.min(1).required(),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
    timeSegments: array()
      .of(timeSegmentSchema)
      .min(1)
      .required()
      .test(
        'noOverlappingTimes',
        'Time slots should not overlap each other. Please try again.',
        (timeSegments) => !hasOverlappingTimes(timeSegments),
      ),
  }),
)

export type CreateAvailabilityFormValues = InferType<typeof CreateAvailabilityFormSchema>

export type CreateAvailabilityRequestBody = Omit<CreateAvailabilityFormValues, 'timeSegments'> & {
  timeSegments: [string, string][]
}

export const ProviderScheduleSlotsFilterSchema = object({
  startDate: string().required(),
  endDate: string().required(),
}).required()

export type ProviderScheduleSlotsFilter = InferType<typeof ProviderScheduleSlotsFilterSchema>
