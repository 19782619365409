import { lazy } from 'react'
import format from 'date-fns/format'
import { isSuccessVisit } from 'constants/visit-types'

const V2ModalsMap = {
  autoLogout: {
    label: 'Still there?',
    Content: lazy(() => import('../v2Modals/AutoLogout/Content')),
    Footer: lazy(() => import('../v2Modals/AutoLogout/Footer')),
  },
  confirmEndCall: {
    label: ({ visit }) => `Are you sure you want to end this ${visit?.isSuccess ? 'session' : 'visit'}?`,
    Content: lazy(() => import('../v2Modals/ConfirmEndCall/Content')),
    Footer: lazy(() => import('../v2Modals/ConfirmEndCall/Footer')),
  },
  rescheduleVisit: {
    label: 'Reschedule Visit',
    Content: lazy(() => import('../v2Modals/RescheduleVisit')),
  },
  timeSlotSave: {
    label: 'Time Slots Saved',
    Content: lazy(() => import('../v2Modals/TimeSlotSave/Content')),
    Footer: lazy(() => import('../v2Modals/TimeSlotSave/Footer')),
  },
  unsavedTimeSlots: {
    label: 'Unsaved Changes',
    Content: lazy(() => import('../v2Modals/UnsavedTimeSlots/Content')),
    Footer: lazy(() => import('../v2Modals/UnsavedTimeSlots/Footer')),
  },
  memberMedication: {
    label: ({ medicationId }) => `${medicationId ? 'Edit' : 'Add'} Medication`,
    Content: lazy(() => import('../v2Modals/MemberMedication/Content')),
    Footer: lazy(() => import('../v2Modals/MemberMedication/Footer')),
    Container: lazy(() => import('../v2Modals/MemberMedication/Container')),
    footerVariant: 'right',
  },
  addPrescription: {
    label: 'Add Prescription',
    Content: lazy(() => import('../v2Modals/AddPrescription/Content')),
    Footer: lazy(() => import('../v2Modals/AddPrescription/Footer')),
    Container: lazy(() => import('../v2Modals/AddPrescription/Container')),
    footerVariant: 'right',
  },
  medicationInteractionAlert: {
    label: 'Medication Interaction Alert',
    Content: lazy(() => import('../v2Modals/MedicationInteractionAlert/Content')),
    Footer: lazy(() => import('../v2Modals/MedicationInteractionAlert/Footer')),
    Container: lazy(() => import('../v2Modals/MedicationInteractionAlert/Container')),
    size: 'l',
    footerVariant: 'right',
  },
  editRxChangePrescription: {
    label: 'Edit Prescription',
    Content: lazy(() => import('../v2Modals/EditRxChangePrescription/Content')),
    Footer: lazy(() => import('../v2Modals/EditRxChangePrescription/Footer')),
    Container: lazy(() => import('../v2Modals/EditRxChangePrescription/Container')),
    footerVariant: 'right',
  },
  confirmRxChangeRequest: {
    label: 'Approve Change Request',
    Content: lazy(() => import('../v2Modals/ConfirmRxChangeRequest/Content')),
    Footer: lazy(() => import('../v2Modals/ConfirmRxChangeRequest/Footer')),
    footerVariant: 'right',
  },
  denyRxChangeRequest: {
    label: 'Deny Change Request',
    Content: lazy(() => import('../v2Modals/DenyRxChangeRequest/Content')),
    Footer: lazy(() => import('../v2Modals/DenyRxChangeRequest/Footer')),
    Container: lazy(() => import('../v2Modals/DenyRxChangeRequest/Container')),
    footerVariant: 'right',
  },
  pdfViewer: {
    Content: lazy(() => import('../v2Modals/PDFViewer/Content')),
    label: 'Policy',
    size: 'custom',
    className: 'w-[1000px] max-sm:max-h-[95dvh] [&>_:nth-child(2)]:!overflow-auto !overflow-hidden',
    footerVariant: 'right',
  },
} as const

export type V2ModalMapType = keyof typeof V2ModalsMap

export default V2ModalsMap
