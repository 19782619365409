import { object, string, number, mixed, boolean, lazy, InferType } from 'yup'
import { RxDaysSupplySchema } from './NewPrescription'

const SingleStrengthSchema = object().shape({
  unit: string().notRequired().nullable(),
  ndc: string().required('Required'),
})

const SingleMedicationNameSchema = object().shape({
  name: string().trim().required('Required'),
})

export const interactionOverrideReasons = [
  'Benefits outweigh the risks',
  'Clinical necessity',
  'Current therapy',
  'Medication is needed for short-term use',
  'Member has a history of tolerating the interacting medication without adverse effects',
  'Member has failed alternative therapy / No alternative medication available',
  'Not clinically significant',
  'Will change and/or address other medication(s)',
  'Will monitor and/or take precautions',
  'Other',
]

const InteractionOverrideReasonFieldSchema = string()
  .label('Reason for Override')
  .oneOf(interactionOverrideReasons, '${path} is a required field')
  .nullable()

const InteractionOverrideReasonOtherFieldSchema = string()
  .max(255, 'Other field is too long')
  .when('interactionOverrideReason', {
    is: (v) => v === 'Other',
    then: (schema) => schema.required('Required'),
    otherwise: (schema) => schema.notRequired().nullable(),
  })

export const InteractionOverrideReasonSchema = object().shape({
  interactionOverrideReason: InteractionOverrideReasonFieldSchema.required(),
  interactionOverrideReasonNote: InteractionOverrideReasonOtherFieldSchema,
})

export type InteractionOverrideReasonValues = InferType<typeof InteractionOverrideReasonSchema>

export const BaseRxSchema = object().shape({
  substitutions: boolean().required('Required'),
  refills: number()
    .typeError('Must be a number')
    .integer('Must be a whole number')
    .min(0, 'Cannot be negative')
    .max(99, 'Too many')
    .required('Required'),
  daysSupply: lazy((val) =>
    val === '' || val === null || val === undefined ? string().notRequired().nullable() : RxDaysSupplySchema,
  ),
  directions: string().trim().min(1, 'Too short').max(140, 'Too long').required('Required'),
  quantity: number()
    .typeError('Must be a number')
    .transform((value) => Number(value))
    .min(0, 'Minimum quantity is 0')
    .test('test-name', 'Must be under 10 digits', function (value) {
      const { path, createError } = this
      const stringVal = `${value}`.replace('.', '')
      const result = stringVal.length <= 10
      if (!result) createError({ path, message: 'Must be under 11 digits' })
      return result
    })
    .when({
      is: (quantity) => quantity === Math.round(quantity),
      then: number().integer(),
    })
    .required('Required'),
  pharmacyNotes: string().trim().max(140, 'Too Long'),
  dispensableDrugId: string().required('Required'),
  interactionId: number().notRequired().nullable(),
  interactionAccepted: boolean().notRequired().nullable(),
  interactionOverrideReason: InteractionOverrideReasonFieldSchema.when('interactionAccepted', {
    is: (v) => !!v,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  interactionOverrideReasonNote: InteractionOverrideReasonOtherFieldSchema,
})

export const AddPrescriptionOldSchema = object()
  .shape({
    medicationSearch: mixed().when({
      is: (v) => !v,
      then: mixed().required('Required'),
      otherwise: SingleMedicationNameSchema,
    }),
    dispenseUnit: mixed().when({
      is: (v) => !v,
      then: mixed(),
      otherwise: number(),
    }),
    schedule: number().required('Required'),
  })
  .concat(BaseRxSchema)
  .concat(SingleStrengthSchema)

export const AddPrescriptionSchema = object()
  .shape({
    name: string().trim().required('Required'),
    dispenseUnit: mixed().when({
      is: (v) => !v,
      then: mixed(),
      otherwise: number(),
    }),
    schedule: number().required('Required'),
  })
  .concat(BaseRxSchema)
  .concat(SingleStrengthSchema)

const QuerySchema = object().shape({
  visitId: string().required(),
  rx: object()
    .shape({
      pharmacyId: string().required(),
      memberId: string().required(),
      providerId: string().required(),
      unitsSourceId: mixed().required('Required'),
    })
    .concat(BaseRxSchema)
    .concat(SingleMedicationNameSchema)
    .concat(SingleStrengthSchema),
})

export const RX_CHANGE_DENY_REASONS = [
  { value: 4, label: 'Unknown Patient' },
  { value: 5, label: 'Patient Not Under Care' },
  { value: 6, label: 'Patient No Longer Under Patient Care' },
  { value: 7, label: 'Too Soon' },
  { value: 8, label: 'Never Prescribed' },
  { value: 9, label: 'Have Patient Contact' },
  { value: 14, label: 'Change Inappropriate' },
  { value: 15, label: 'Need Appointment' },
  { value: 16, label: 'Prescriber Not Associated With Location' },
  { value: 17, label: 'No Prior Auth Attempt' },
  { value: 18, label: 'Already Handled' },
  { value: 21, label: 'At Patient Request' },
  { value: 22, label: 'Patient Allergic To Request Medication' },
  { value: 23, label: 'Medication Discontinued' },
]

export const DenyRxChangeSchema = object().shape({
  reason: number()
    .oneOf(Object(RX_CHANGE_DENY_REASONS).map(({ value }) => value))
    .required(),
})

export default QuerySchema
