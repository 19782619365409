export const DASHBOARD = 920
export const DASHBOARD_CARDS_SIZE_CHANGE = 1180
export const MEMBER_INFO_COLLAPSE_BOUND = 499
export const VIDEO_VISIT_REARRANGEMENT = 500
export const VISIT_PANEL_ACTIONS_REARRANGEMENT = 750
export const VISITS_LIST_REARRANGEMENT = 680
export const VISIT_DUE_TO_MENU_REARRANGEMENT = 921
export const VISIT_CARD_MOBILE_REDUCTION = VIDEO_VISIT_REARRANGEMENT
export const MODALS_WIDTH_MOBILE_REDUCTION = VIDEO_VISIT_REARRANGEMENT
export const MODAL_INPUTS_MOBILE_REDUCTION = 550
export const MODALS_WIDTH_SMALL_MOBILE_REDUCTION = 390
export const MODALS_ICONS_INDENTATION_DECREASE = MODALS_WIDTH_SMALL_MOBILE_REDUCTION
export const MODALS_INDENTATION_FOR_SMALL_SCREENS = 350
export const MODALS_ADD_ADDENDUM_FULL_WIDTH = 450
export const STACKING_TABLES_MOBILE_REARRANGEMENT = 768
export const PHARMACY_BLOCK_REARRANGEMENT = 768
