import { ProviderSchedule } from 'constants/schedule'
import { InferType, object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = ProviderSchedule[]

export const url = ({ providerId }: Request) => `/v3/providers/${providerId}/schedules`
